import {
    Content,
    ContentHeader,
    Header,
    Page,
    SupportButton,
} from '@backstage/core-components';


import {
    WelcomeTitle,
    HomePageToolkit,
} from '@backstage/plugin-home';

import { XkcdComicCard } from 'backstage-plugin-xkcd';

import React from 'react';
import {
    SageHrIcon,
    ConfluenceIcon,
    OWAIcon,
    VaultwardenIcon,
    NextcloudIcon,
    GitLabIcon,
    GitHubIcon,
    MiroIcon,
    FigmaIcon,
    TrelloIcon,
    SlackIcon,
  } from '../../assets/icons/customIcons';

import { Grid } from '@material-ui/core';

export const HomePage = (
    <Page themeId="home">
        <Header title={<WelcomeTitle />} pageTitleOverride="Home" />
        <Content>
            <ContentHeader title="">
                <SupportButton>Your start page</SupportButton>
            </ContentHeader>
            <Grid container spacing={3}>
                <Grid item md={8} xs={12}>
                    <HomePageToolkit
                    tools={[
                        {
                            url: 'https://inspiredconsultinggmbh.sage.hr/',
                            label: 'Sage HR',
                            icon: <SageHrIcon />,
                        },
                        {
                            url: 'https://outlook.office.com/',
                            label: 'Outlook OWA',
                            icon: <OWAIcon />,
                        },
                        {
                            url: 'https://inspired-consulting.atlassian.net/',
                            label: 'Confluence',
                            icon: <ConfluenceIcon />,
                        },
                        {
                            url: 'https://vaultwarden.inspired.jetzt/',
                            label: 'Vaultwarden',
                            icon: <VaultwardenIcon />,
                        },
                        {
                            url: 'https://nextcloud.inspired.consulting/',
                            label: 'Nextcloud',
                            icon: <NextcloudIcon />,
                        },
                        {
                            url: 'https://inspired-ag.slack.com/',
                            label: 'Slack',
                            icon: <SlackIcon />,
                        },
                        {
                            url: 'https://gitlab.com/ag.inspired/',
                            label: 'GitLab',
                            icon: <GitLabIcon />,
                        },
                        {
                            url: 'https://github.com/inspired-consulting/',
                            label: 'GitHub',
                            icon: <GitHubIcon />,
                        },
                        {
                            url: 'https://miro.com/app/dashboard/',
                            label: 'Miro',
                            icon: <MiroIcon />,
                        },
                        {
                            url: 'https://www.figma.com/',
                            label: 'Figma',
                            icon: <FigmaIcon />,
                        },
                        {
                            url: 'https://trello.com/',
                            label: 'Trello',
                            icon: <TrelloIcon />,
                        },
                    ]}
                />
                </Grid>
                <Grid item md={4} xs={12}>
                    <XkcdComicCard />
                </Grid>
            </Grid>
        </Content>
    </Page>
);
